import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { isNumber, map } from 'lodash/fp';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  getSpaceApiUrl,
  SPACES_API_URL,
  spacesQueryKeys,
} from './spaces.constants';
import {
  CreateSpaceMutationParams,
  MoveSpaceMutationParams,
  NonNormalizedSpaceType,
  SpaceDetailsType,
  SpaceType,
  UpdateSpaceMutationParams,
} from './spaces.types';
import { normalizeSpacePath } from './spaces.utils';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { globalQueryKeys } from '../global-query-keys';

export function useSpaces(queryOptions?: UseQueryOptions<SpaceType[]>) {
  const { url: adjustedUrl, options } = useRequestOptions({
    url: SPACES_API_URL,
  });

  return useQuery<SpaceType[]>({
    queryKey: spacesQueryKeys.base,
    queryFn: async () => {
      const nonNormalizedSpaces = await fetchApiRequest(adjustedUrl, options);

      return map(normalizeSpacePath, nonNormalizedSpaces);
    },
    cacheTime: 0,
    meta: {
      baseUrl: SPACES_API_URL,
      method: 'GET',
    },
    ...queryOptions,
  });
}

export function useSpace(params: {
  spaceId: SpaceType['id'] | null | undefined;
  fallbackToRootSpace?: boolean;
  queryOptions?: Parameters<typeof useSpaces>[0];
}) {
  const { spaceId, fallbackToRootSpace, queryOptions } = params;

  const spaces = useSpaces(queryOptions);

  if (!spaceId && fallbackToRootSpace) {
    return findRootSpace(spaces.data);
  }

  const space = spaces.data?.find((space) => space.id === spaceId);

  if (space) return space;

  if (fallbackToRootSpace) {
    return findRootSpace(spaces.data);
  }

  return;
}

function findRootSpace(spaces: SpaceType[] | undefined) {
  return spaces?.find((space) => space.space_type === 'root');
}

// TODO: Remove `useSpace`, rename to `useSpace` & update all usages
export function useSpaceDetails(spaceId: SpaceType['id']) {
  return useApiQuery<SpaceDetailsType>(
    `${SPACES_API_URL}/${spaceId}`,
    spacesQueryKeys.detail(spaceId),
    {
      staleTime: 0,
      enabled: isNumber(spaceId),
      // @ts-ignore
      select: (space: NonNormalizedSpaceType) => normalizeSpacePath(space),
    }
  );
}

export function useUpdateSpace() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<SpaceType, ServerError, UpdateSpaceMutationParams>({
    mutationFn: ({ spaceId, updatedSpace }) => {
      return fetchApiRequest(`${url}${getSpaceApiUrl(spaceId)}`, {
        ...options,
        body: JSON.stringify(updatedSpace),
      });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateSpace',
      baseUrl: `${SPACES_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

interface UseUpdateSpaceMaintenanceRequestPayload {
  spaceId: SpaceType['id'];
  enabled: boolean;
  closeAllIncidents?: boolean;
}

export function useUpdateSpaceMaintenance() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<
    NonNormalizedSpaceType,
    ServerError,
    UseUpdateSpaceMaintenanceRequestPayload
  >({
    mutationFn: ({ spaceId, enabled, closeAllIncidents }) => {
      return fetchApiRequest(`${url}${getSpaceApiUrl(spaceId)}/maintenance`, {
        ...options,
        body: JSON.stringify({
          enabled,
          close_all_incidents: closeAllIncidents,
        }),
      });
    },
    onSuccess: (_, variables) => {
      dispatch(toastrSuccess('Space maintenance mode updated successfully'));

      queryClient.invalidateQueries(spacesQueryKeys.base);

      if (variables.closeAllIncidents) {
        queryClient.invalidateQueries(globalQueryKeys.incidents);
      }
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

export function useCreateSpace() {
  const dispatch = useDispatch();
  const spaces = useSpaces();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: SPACES_API_URL,
    method: 'POST',
  });

  return useMutation<SpaceType, ServerError, CreateSpaceMutationParams>({
    mutationFn: async ({ parentSpaceId, newSpace, logoUrl }) => {
      const response: NonNormalizedSpaceType = await fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          parent_id: parentSpaceId,
          icon_url: logoUrl,
          ...newSpace,
        }),
      });

      await spaces.refetch();

      return normalizeSpacePath(response);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateSpace',
      baseUrl: SPACES_API_URL,
      method: 'POST',
    },
  });
}

export function useDeleteSpace() {
  const dispatch = useDispatch();
  const spaces = useSpaces();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'DELETE',
  });

  return useMutation<void, ServerError, number>({
    mutationFn: async (spaceId) => {
      await fetchApiRequest(`${url}/${getSpaceApiUrl(spaceId)}`, options);
    },
    onSuccess: (_, spaceId) => {
      spaces.refetch();
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteSpace',
      baseUrl: `${SPACES_API_URL}/:id`,
      method: 'DELETE',
    },
  });
}

export function useMoveSpace() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const spaces = useSpaces();

  const { url, options } = useRequestOptions({
    url: '',
    method: 'PUT',
  });

  return useMutation<void, ServerError, MoveSpaceMutationParams>({
    mutationFn: async ({ spaceId, targetSpaceId, position }) => {
      await fetchApiRequest(`${url}/${getSpaceApiUrl(spaceId)}/move`, {
        ...options,
        body: JSON.stringify({
          parent_id: targetSpaceId,
          position,
        }),
      });
    },
    onSuccess: () => {
      spaces.refetch();
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useMoveSpace',
      baseUrl: `${SPACES_API_URL}/:id/move`,
      method: 'PUT',
    },
  });
}
