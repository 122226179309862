import {
  AddExternalGroupAccessModal,
  AddExternalGroupAccessModalProps,
} from './access/AddExternalGroupAccessModal';
import {
  AddExternalUserAccessModal,
  AddExternalUserAccessModalProps,
} from './access/AddExternalUserAccessModal';
import {
  AddInternalGroupAccessModal,
  AddInternalGroupAccessModalProps,
} from './access/AddInternalGroupAccessModal';
import {
  AddInternalUserAccessModal,
  AddInternalUserAccessModalProps,
} from './access/AddInternalUserAccessModal';
import {
  AddDeviceModal as AddDeviceModalV1,
  AddDeviceModalProps as AddDeviceModalPropsV1,
} from './add-device/AddDeviceModal';
import {
  AddDeviceModal,
  AddDeviceModalProps,
} from './add-device-v2/AddDeviceModal';
import {
  ClaimDeviceModal,
  ClaimDeviceModalProps,
} from './add-device-v2/ClaimDeviceModal/ClaimDeviceModal';
import {
  SelectModelFromPartnerModal,
  SelectModelFromPartnerModalProps,
} from './add-device-v2/SelectModelFromPartnerModal';
import {
  SelectModelModal,
  SelectModelModalProps,
} from './add-device-v2/SelectModelModal';
import { AddAddress, type AddAddressProps } from './AddAddress';
import { AddAssetModal, type AddAssetModalProps } from './AddAssetModal';
import { AddCreditCard, AddCreditCardProps } from './AddCreditCard';
import { AddUserModal } from './AddUserModal';
import {
  AddUserTypeModal,
  type AddUserTypeModalProps,
  type InternalOrExternal,
} from './AddUserTypeModal';
import { AdminWelcomeVideoModal } from './AdminWelcomeVideoModal';
import { ArchiveCardModal, ArchiveCardModalProps } from './ArchiveCardModal';
import { BulkResolveIncidentsModal } from './BulkResolveIncidentsModal';
import {
  AddC2cConnectorModal,
  AddC2cConnectorModalProps,
} from './c2c-connectors/AddC2cConnectorModal';
import {
  BrightSignConnectorSettingsModal,
  BrightSignConnectorSettingsModalProps,
  QSysConnectorSettingsModal,
  QSysConnectorSettingsModalProps,
  XiOConnectorSettingsModal,
  XiOConnectorSettingsModalProps,
} from './c2c-connectors/c2c-connector-settings';
import {
  ComingSoonC2cIntegrationModal,
  type ComingSoonC2CIntegrationModalProps,
} from './c2c-connectors/ComingSoonC2cIntegrationModal';
import {
  ThirdPartyC2CConnectorsModal,
  type ThirdPartyC2CConnectorsModalProps,
} from './c2c-connectors/ThirdPartyC2cConnectorsModal';
import {
  CecMigrationWelcomeModal,
  type CecMigrationWelcomeModalProps,
} from './cec-connect/CecMigrationWelcomeModal';
import {
  CeCTrialAnnouncementModal,
  type CeCTrialAnnouncementModalProps,
} from './cec-connect/CeCTrialAnnouncementModal';
import {
  ConfirmCecConnectionModal,
  type ConfirmCecConnectionModalProps,
} from './cec-connect/ConfirmCecConnectionModal';
import {
  ConnectionSuccessModal,
  type ConnectionSuccessModalProps,
} from './cec-connect/ConnectionSuccessModal';
import {
  ChangeSpaceModal,
  type ChangeSpaceModalProps,
} from './ChangeSpaceModal';
import { Connect } from './Connect';
import {
  ContactServiceProviderModal,
  ContactServiceProviderModalProps,
} from './ContactServiceProvider';
import {
  ContactSupportModal,
  type ContactSupportModalProps,
} from './ContactSupportModal';
import { ContactSupportSuccessModal } from './ContactSupportSuccessModal';
import {
  CreateCustomerSpaceModal,
  CreateCustomerSpaceModalProps,
} from './create-customer/CreateCustomerSpaceModal';
import { CreateTicketModal, CreateTicketModalProps } from './CreateTicketModal';
import {
  DeleteCustomerSpaceModal,
  DeleteCustomerSpaceModalProps,
} from './DeleteCustomerSpaceModal';
import {
  DeleteDeviceModal,
  DeleteDeviceModalProps,
} from './DeleteDeviceModal/DeleteDeviceModal';
import { DeleteUserModal } from './DeleteUserModal';
import { type FailedDeviceClaimTroubleshootingStepsModalProps } from './device-troubleshooting-steps/FailedDeviceClaimTroubleshootingStepsModal';
import { DismissSetupGuideModal } from './DismissSetupGuideModal';
import {
  ExportDeviceStateHistoryModal,
  ExportDeviceStateHistoryModalProps,
} from './export-device-state-history/ExportDeviceStateHistoryModal';
import { FailedCreditCard } from './FailedCreditCard';
import { FailedOrder } from './FailedOrder';
import FeatureFlags from './FeatureFlags';
import { FreeTrialModal } from './free-trial/FreeTrialModal';
import {
  AddInternalUsersToNewGroupModal,
  type AddInternalUsersToNewGroupModalProps,
} from './groups/create-group/AddInternalUsersToNewGroupModal';
import { CreateNewGroupModal } from './groups/create-group/CreateNewGroupModal';
import {
  AddExternalUserModal,
  type AddExternalUserModalProps,
} from './groups/edit-group/AddExternalUserModal';
import {
  AddInternalUsersToExistingGroupModal,
  type AddInternalUsersToExistingGroupModalProps,
} from './groups/edit-group/AddInternalUsersToExistingGroupModal';
import {
  EditGroupModal,
  type EditGroupModalProps,
} from './groups/edit-group/EditGroupModal';
import { MoveDevice, type MoveDeviceProps } from './move-device/MoveDevice';
import { OnboardingToursCompletedModal } from './OnboardingToursCompletedModal';
import {
  PaymentsSuccessfullyTransferredModal,
  PaymentsSuccessfullyTransferredModalProps,
} from './PaymentsSuccessfullyTansferredModal';
import {
  PostPurchaseParametersModal,
  PostPurchaseParametersModalProps,
} from './post-purchase-parameters/PostPurchaseParametersModal';
import { PreventProductPurchase } from './PreventProductPurchase';
import { PriceQuoteLoader } from './PriceQuote/Loader';
import { PriceQuotePreview } from './PriceQuote/Preview';
import { ReportIncidentModal } from './ReportIncidentModal';
import {
  ResolveIncidentModal,
  type ResolveIncidentModalProps,
} from './ResolveIncidentModal';
import { RunCommand, type RunCommandProps } from './RunCommand';
import {
  SpaceMaintenanceActivationModal,
  type SpaceMaintenanceActivationModalProps,
} from './SpaceMaintenanceActivationModal';
import {
  SubscriptionCancellationFlowModal,
  type SubscriptionCancellationFlowModalProps,
} from './subscription-cancellation/SubscriptionCancellationFlowModal';
import {
  SubscriptionCancellationSuccessModal,
  type SubscriptionCancellationSuccessModalProps,
} from './SubscriptionCancellationSuccessModal';
import {
  SubscriptionRenewalModal,
  type SubscriptionRenewalModalProps,
} from './SubscriptionRenewalModal';
import {
  SuggestBrandModal,
  type SuggestBrandModalProps,
} from './SuggestBrandModal';
import {
  TourCompletedModal,
  type TourCompletedModalProps,
} from './TourCompletedModal';
import {
  TransferPaymentModal,
  TransferPaymentModalProps,
} from './TransferPaymentModal';
import {
  RedeemLicenseCodeModal,
  type RedeemLicenseCodeModalProps,
} from './unassigned-licenses/RedeemLicenseCodeModal';
import {
  UnassignedLicenses,
  type UnassignedLicensesModalData,
} from './unassigned-licenses/UnassignedLicenses';
import {
  UpgradePricingPlanModal,
  type UpgradePricingPlanModalProps,
} from './UpgradePricingPlanModal';
import {
  FileInfoUploader,
  FileInfoUploaderProps,
} from './upload-file/FileInfoUploader';
import { SpaceFileUploader } from './upload-file/SpaceFileUploader';
import {
  UploadAssetWarrantyModal,
  type UploadAssetWarrantyModalProps,
} from './upload-file/UploadAssetWarrantyModal';
import {
  UploadDeviceWarrantyModal,
  UploadDeviceWarrantyModalData,
} from './upload-file/UploadDeviceWarrantyModal';
import { WelcomeVideoAvailableLaterModal } from './WelcomeVideoAvailableLaterModal';
import { WelcomeVideoModal } from './WelcomeVideoModal';
import { WidgetPreview } from './WidgetPreview/WidgetPreview';

export const desktopModals = {
  UploadAssetWarrantyModal,
  AddDeviceModal,
  AddAddress,
  AddCreditCard,
  RunCommand,
  ReportIncidentModal,
  CreateTicketModal,
  BulkResolveIncidentsModal,
  ResolveIncidentModal,
  FileInfoUploader,
  AddAssetModal,
  ContactServiceProviderModal,
  SpaceFileUploader,
  FailedCreditCard,
  ExportDeviceStateHistoryModal,
  TreeMoveDevice: MoveDevice,
  FailedOrder,
  PreventProductPurchase,
  MoveDevice,
  FeatureFlags,
  Connect,
  PriceQuoteLoader,
  PriceQuotePreview,
  UnassignedLicenses,
  UploadDeviceWarrantyModal,
  WidgetPreview,
  AddUserModal,
  EditGroupModal,
  AddUserTypeModal,
  AddExternalUserModal,
  AddInternalUsersToExistingGroupModal,
  CreateNewGroupModal,
  AddInternalUsersToNewGroupModal,
  AddExternalGroupAccessModal,
  AddInternalGroupAccessModal,
  AddExternalUserAccessModal,
  AddInternalUserAccessModal,
  UpgradePricingPlanModal,
  PostPurchaseParametersModal,
  FreeTrialModal,
  SubscriptionCancellationSuccessModal,
  SubscriptionRenewalModal,
  DeleteUserModal,
  TourCompletedModal,
  AdminWelcomeVideoModal,
  WelcomeVideoModal,
  OnboardingToursCompletedModal,
  DismissSetupGuideModal,
  SubscriptionCancellationFlowModal,
  WelcomeVideoAvailableLaterModal,
  TransferPaymentModal,
  PaymentsSuccessfullyTransferredModal,
  ArchiveCardModal,
  ChangeSpaceModal,
  DeleteDeviceModal,
  ClaimDeviceModal,
  SelectModelFromPartnerModal,
  SelectModelModal,
  AddDeviceModalV1,
  SuggestBrandModal,
  ContactSupportSuccessModal,
  RedeemLicenseCodeModal,
  ConfirmCecConnectionModal,
  ConnectionSuccessModal,
  CecMigrationWelcomeModal,
  SpaceMaintenanceActivationModal,
  AddC2cConnectorModal,
  XiOConnectorSettingsModal,
  QSysConnectorSettingsModal,
  BrightSignConnectorSettingsModal,
  CreateCustomerSpaceModal,
  DeleteCustomerSpaceModal,
  CeCTrialAnnouncementModal,
  ContactSupportModal,
  ComingSoonC2cIntegrationModal,
  ThirdPartyC2CConnectorsModal,
};

export {
  type UnassignedLicensesModalData,
  type UploadDeviceWarrantyModalData,
  type MoveDeviceProps,
  type EditGroupModalProps,
  type AddUserTypeModalProps,
  type InternalOrExternal,
  type AddExternalUserModalProps,
  type AddInternalUsersToExistingGroupModalProps,
  type AddInternalUsersToNewGroupModalProps,
  type AddExternalGroupAccessModalProps,
  type AddInternalGroupAccessModalProps,
  type AddExternalUserAccessModalProps,
  type AddInternalUserAccessModalProps,
  type UpgradePricingPlanModalProps,
  type AddAddressProps,
  type PostPurchaseParametersModalProps,
  type SubscriptionCancellationSuccessModalProps,
  type SubscriptionRenewalModalProps,
  type ContactServiceProviderModalProps,
  type TourCompletedModalProps,
  type ExportDeviceStateHistoryModalProps,
  type SubscriptionCancellationFlowModalProps,
  type PaymentsSuccessfullyTransferredModalProps,
  type TransferPaymentModalProps,
  type ArchiveCardModalProps,
  type AddCreditCardProps,
  type FileInfoUploaderProps,
  type AddDeviceModalProps,
  type ChangeSpaceModalProps,
  type DeleteDeviceModalProps,
  type FailedDeviceClaimTroubleshootingStepsModalProps,
  type CreateTicketModalProps,
  type ClaimDeviceModalProps,
  type SelectModelFromPartnerModalProps,
  type SelectModelModalProps,
  type AddDeviceModalPropsV1,
  type RedeemLicenseCodeModalProps,
  type ConfirmCecConnectionModalProps,
  type ConnectionSuccessModalProps,
  type CecMigrationWelcomeModalProps,
  type SpaceMaintenanceActivationModalProps,
  type CreateCustomerSpaceModalProps,
  type DeleteCustomerSpaceModalProps,
  type XiOConnectorSettingsModalProps,
  type BrightSignConnectorSettingsModalProps,
  type QSysConnectorSettingsModalProps,
  type AddC2cConnectorModalProps,
  type SuggestBrandModalProps,
  type CeCTrialAnnouncementModalProps,
  type AddAssetModalProps,
  type ResolveIncidentModalProps,
  type ContactSupportModalProps,
  type UploadAssetWarrantyModalProps,
  type RunCommandProps,
  type ComingSoonC2CIntegrationModalProps,
  type ThirdPartyC2CConnectorsModalProps,
};
